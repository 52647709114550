import React, { Component } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class Testimonials extends Component {
    render() {
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                slidesToSlide: 1
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            }
        };

        if (this.props.resumeTestimonials && this.props.resumeBasicInfo) {
            var sectionName = this.props.resumeBasicInfo.section_name.testimonials;
            var testimonials = this.props.resumeTestimonials.map(function (testimonials, i) {
                return (
                    <div className="col-md-12 col-sm-12 p-3" key={i}>

              <div className="text-center testimonial-title">
                  <div className="testimonial-icon mb-3"><img src={testimonials.icon} width="50px" height="50px" className="image-test" alt="Client Logo" /></div>
                  <p
                      className="text-justify p-3 testimonial-description"
                      style={{ fontSize: "150%", marginTop: "4px" , minHeight: "200px"}}
                  >
                    {testimonials.description}
                  </p>
                  <div className="testimonial-client" style={{fontSize: "135%", textDecoration:"overline"}}>{testimonials.client}</div>
              </div>
                    </div>
                );
            });
        }

        if(testimonials) {
            return (
                <section id="testimonials">
                    <div className="col-md-12">
                        <div className="col-md-12">
                            <h1 className="section-title" style={{color: "black"}}>
                                <span className="">{sectionName}</span>
                            </h1>
                        </div>
                        {/*<div className="col-md-12 text-center">
                            <div className="mx-auto testimonials-main row">{testimonials}</div>
                        </div>*/}
                        <Carousel swipeable={true}
                                  draggable={true}
                                  showDots={false}
                                  responsive={responsive}
                                  infinite={true}
                                  autoPlay={true}
                                  transitionDuration={1000}
                                 >
                            {testimonials}
                        </Carousel>;
                    </div>
                </section>
            );
        }else{
            return (
                <section id="testimonials">
                    <div className="col-md-12">
                        <div className="col-md-12">
                            <h1 className="section-title" style={{color: "black"}}>
                                <span className="">{sectionName}</span>
                            </h1>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="mx-auto testimonials-main row">{testimonials}</div>
                        </div>
                    </div>
                </section>
            );
        }
    }
}

export default Testimonials;
