import React, { Component } from "react";
import $ from "jquery";

class Skills extends Component {
  render() {
    if (this.props.sharedSkills && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.skills;
      var skills = this.props.sharedSkills.icons.map(function (skills, i) {
        return (
          <li className="list-inline-item mx-3 skill-base" data-filter={skills.category} key={i}>
            <span>
              <div className="text-center skills-tile">
                <i className={skills.class} style={{ fontSize: "220%" }}>
                  <p
                    className="text-center"
                    style={{ fontSize: "30%", marginTop: "4px" }}
                  >
                    {skills.name}
                  </p>
                </i>
              </div>
            </span>
          </li>
        );
      });
    }

    const handleSkill= (e) => {
      e.preventDefault();
      let filter = e.target.getAttribute('data-filter');
      if(filter.trim() == '') {
        $('.skill-base').show();
      }else{
        $('.skill-base').each(function(){
          if($(this).data('filter') == filter)
            $(this).show();
          else
            $(this).hide();
        });
      }
      $('.sel-filter.active').removeClass('active');
      e.target.classList.add('active');
    }

    return (
      <section id="skills">
        <div className="col-md-12">
          <div className="col-md-12">
            <h1 className="section-title">
              <span className="text-white">{sectionName}</span>
            </h1>
          </div>
          <div className="col-md-12 text-center">

            <div className="d-flex justify-content-center">
              <div className="sel-filter active" data-filter="" onClick={handleSkill}>All</div>
              <div className="sel-filter" data-filter="Frontend" onClick={handleSkill}>Front End</div>
              <div className="sel-filter" data-filter="Backend" onClick={handleSkill}>Back End</div>
              <div className="sel-filter" data-filter="Mobile" onClick={handleSkill}>Mobile</div>
              <div className="sel-filter" data-filter="Database" onClick={handleSkill}>Database</div>
              <div className="sel-filter" data-filter="Server" onClick={handleSkill}>Servers</div>
              <div className="sel-filter" data-filter="API" onClick={handleSkill}>APIs</div>
              <div className="sel-filter" data-filter="CMS" onClick={handleSkill}>CMS</div>
              <div className="sel-filter" data-filter="Version" onClick={handleSkill}>Version Control</div>
              <div className="sel-filter" data-filter="Design" onClick={handleSkill}>Design</div>
              <div className="sel-filter" data-filter="Others" onClick={handleSkill}>Others</div>
            </div>

            <ul className="list-inline mx-auto skill-icon">{skills}</ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
