import React, { Component } from "react";
import ProjectDetailsModal from "./ProjectDetailsModal";
import $ from "jquery";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deps: {},
      detailsModalShow: false,
    };
  }

  render() {
    let detailsModalShow = (data) => {
      this.setState({ detailsModalShow: true, deps: data });
    };

    const handleFilter= (e) => {
      e.preventDefault();
      let filter = e.target.getAttribute('data-filter');
      if(filter.trim() == '') {
        $('.projects-base').show();
      }else{
        $('.projects-base').each(function(){
            if($(this).data('filter') == filter)
              $(this).show();
            else
              $(this).hide();
        });
      }
      $('.sel-filter.active').removeClass('active');
      e.target.classList.add('active');
    }

    let detailsModalClose = () => this.setState({ detailsModalShow: false });
    if (this.props.resumeProjects && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.projects;
      var projects = this.props.resumeProjects.map(function (projects) {
        return (
          <div
            className="col-sm-12 col-md-6 col-lg-4 projects-base"
            key={projects.title}
            style={{ cursor: "pointer" }}
            data-filter={projects.type}
          >
            <span className="portfolio-item d-block">
              <div className="foto" onClick={() => detailsModalShow(projects)}>
                <div>
                  <img
                      loading="lazy"
                      alt={projects.title}
                    src={projects.images[0]}
                    alt="projectImages"
                    height="230"
                    style={{marginBottom: 0, paddingBottom: 0, position: 'relative'}}
                  />
                  <span className="project-date">{projects.startDate}</span>
                  <br />
                  <p className="project-title-settings mt-3">
                    {projects.title}
                  </p>
                </div>
              </div>
            </span>
          </div>
        );
      });
    }

    return (
      <section id="portfolio">
        <div className="col-md-12">
          <h1 className="section-title" style={{ color: "black" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="col-md-12 mx-auto">
            <div className="d-flex justify-content-center">
              <div className="sel-filter active" data-filter="" onClick={handleFilter}>All</div>
              <div className="sel-filter" data-filter="Wordpress" onClick={handleFilter}>Wordpress</div>
              <div className="sel-filter" data-filter="Custom" onClick={handleFilter}>Custom</div>
              <div className="sel-filter" data-filter="UX/UI" onClick={handleFilter}>UX/UI</div>
              <div className="sel-filter" data-filter="Mobile" onClick={handleFilter}>Mobile</div>
            </div>
            <div className="row mx-auto">{projects}</div>
          </div>
          <ProjectDetailsModal
            show={this.state.detailsModalShow}
            onHide={detailsModalClose}
            data={this.state.deps}
          />
        </div>
      </section>
    );
  }
}

export default Projects;
