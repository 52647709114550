import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";

class ContactForm extends Component {
    render() {
        if (this.props.resumeBasicInfo) {
            var sectionName = this.props.resumeBasicInfo.section_name.reachus;
        }

        const handleClick = (e) => {
            e.preventDefault();
            $.ajax({
                url: `/email.php`,
                dataType: "json",
                method: 'POST',
                cache: false,
                data: $('.frmContact').serialize(),
                success: function (data) {
                   if(data.hasOwnProperty('error'))
                       alert(data.error);
                   else {
                       alert(data.message);
                       document.getElementById("frmContact").reset();
                   }
                }.bind(this),
                error: function (xhr, status, err) {
                    alert(err);
                },
            });
        }


        return (
            <section id="contact-form">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="section-title">
                            <span className="">{sectionName}</span>
                        </h1>
                    </div>
                        <form className="frmContact" id="frmContact" onSubmit={handleClick} style={{width: "100%"}} >
                            <div className="d-flex justify-content-center align-content-center col-md-12">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="row justify-content-center align-content-center">
                                        <div className="col-md-6 form-group mb-4">
                                            <input type="text" className="form-control" placeholder="Full Name" name="fullname" id="full-name" />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <input type="text" className="form-control" placeholder="E-Mail" name="email" id="email"/>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <input type="text" className="form-control" placeholder="Subject" name="subject" id="subject"/>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <textarea rows="8" className="form-control" placeholder="Message" name="message" id="message"></textarea>
                                        </div>
                                        <div className="col-md-6 form-group mb-4">
                                            <ReCAPTCHA theme="dark"
                                                sitekey="6LeCilgpAAAAAPGseu2gEfWXnzc2AXjnuoxOsG1Q"
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <input type="submit" className="btn btn-primary" value="Submit"  style={{backgroundColor:"#6fda44", fontWeight: "bold", borderColor:"#6fda44", color: "#000000"}} />
                                        </div>
                                    </div>
                                </div>
                             </div>
                        </form>
                </div>
            </section>
        );
    }
}

export default ContactForm;
